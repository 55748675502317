// extracted by mini-css-extract-plugin
export var maineDigest = "maine_digest_2021_12-module--maineDigest--4Ffwu";
export var heroImage = "maine_digest_2021_12-module--heroImage--KOhw3";
export var columnWrapper = "maine_digest_2021_12-module--columnWrapper--gwTyA";
export var headingLogo = "maine_digest_2021_12-module--headingLogo--HF-EO";
export var headingWrapper = "maine_digest_2021_12-module--headingWrapper--Tnm2c";
export var heading = "maine_digest_2021_12-module--heading--u+PxJ";
export var instance = "maine_digest_2021_12-module--instance--7VemR";
export var subheading = "maine_digest_2021_12-module--subheading--JzJLb";
export var textWrapper = "maine_digest_2021_12-module--textWrapper--bFRXD";
export var latestnewsarticleheadline = "maine_digest_2021_12-module--latestnewsarticleheadline--CltQm";
export var dateline = "maine_digest_2021_12-module--dateline--iC8Zg";
export var whatsNew = "maine_digest_2021_12-module--whatsNew--i-qSu";
export var photosWrapper = "maine_digest_2021_12-module--photosWrapper--4EfN7";
export var photo = "maine_digest_2021_12-module--photo--M5cQV";
export var photoCaption = "maine_digest_2021_12-module--photoCaption--XnnLx";
export var rightPhotoCaption = "maine_digest_2021_12-module--rightPhotoCaption--VkHZx";
export var rightPhoto = "maine_digest_2021_12-module--rightPhoto--UxsPb";
export var rightCaptions = "maine_digest_2021_12-module--rightCaptions--OwiIB";
export var afterCaption = "maine_digest_2021_12-module--afterCaption--y9Kuw";